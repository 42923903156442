@import '../../settings.scss';

$pageHeaderColor: #5c63e0;
$homePageInputHeight: 44px;

@mixin headings {
    h1, h2, h3,
    h4, h5, h6 {
        @content;
    }
}

#info-page {
    padding-top: 3em;
    padding-bottom: 3em;
    color: #000;
}

#info-page {
    @include headings {
        font-weight: 700;
        margin: 1.8rem 0 1rem 0;
    }

    h1 {
        font-size: 2em;
        margin-top: 0;
    }

    h2 {
        font-size: 1.6em;
    }

    h3 {
        font-size: 1.2em;
    }

    p {
        margin: 0 0 1rem 0;
    }
}