@import 'settings.scss';

* {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
    overscroll-behavior: none;
}

body {
    color: #222;
    font-size: 14px;
    margin: 0;
}

div#root {
    height: 100%;
}
div#layout{
    height: 100%;
}

.flex-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul {
    list-style: disc;
    list-style-position: inside;
    padding-left: 12px;

    li {
        margin-bottom: 0.5em;
    }
}

a {
    color: $linkColor;
}

*:focus {
    border-color: inherit !important;
    box-shadow: inherit !important;
    outline: inherit !important;
}
.btn {
    border: none;
    border-radius: 25px;
    font-family: inherit;
    color: white;
    font-weight: 600;
    padding: 0.5em 1.8em;
}
.btn-primary {
    background-color: #007bff;
}
.btn-primary:hover {
    background-color: $secondaryColor;
}
.btn-primary:focus {
    background-color: $secondaryColor;
}
.btn-primary:active {
    background-color: #000 !important;
}

.form-control {
    border: none;
    border-radius: 0px;
}



.row.row-grid [class*="col-"] + [class*="col-"] {
    margin-top: 15px;
}

@media (min-width: 1200px) {
    .row.row-grid [class*="col-lg-"] + [class*="col-lg-"] {
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .row.row-grid [class*="col-md-"] + [class*="col-md-"] {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .row.row-grid [class*="col-sm-"] + [class*="col-sm-"] {
        margin-top: 0;
    }
}
